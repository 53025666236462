import React, { useContext, useEffect } from "react";
import LinkButton from "../LinkButton/LinkButton";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import * as PartnerPricingStyling from "./PartnerPricing.module.scss";
import { PricingContext } from "../../Layout/Layout";
import { graphql } from "gatsby";

const PartnerPricing = ({ block }) => {
  const structuredText = block.partnerPricing.introText;
  const originalPriceLabel = block.partnerPricing.originalPriceLabel;
  const newPriceLabel = block.partnerPricing.newPriceLabel;
  const voucher = block.partnerPricing.voucher;
  const btn = block.partnerPricing.partnerPricingButton[0];
  const { pricing } = useContext(PricingContext);
  const updateVoucher = pricing.updateVoucher;

  const pricePlan = pricing.hasEssential ? 'essential' : 'unlimited'

  useEffect(() => {
    updateVoucher(voucher);
  }, [updateVoucher, voucher]);

  return (
    <Section
      row="r-middle"
      className={`${PartnerPricingStyling.partnerPricing}`}
    >
      <div className={`col m-3 ${PartnerPricingStyling.priceWrapper}`}>
        <p className="text7">Pricing</p>
        <div className="mb-6 text4">
          {pricing.voucherDiscountPercentage && (
            <>
              <p className="mb-3">{originalPriceLabel}</p>
              <p className={`mb-0 ${PartnerPricingStyling.originalPrice}`}>
                {pricing.renderPrice(
                  pricePlan,
                  pricing.PRICING_PROPERTIES.MONTHLY_ORIGINAL_PRICE
                )}
              </p>
              <p>/month per zone</p>
            </>
          )}
        </div>
        <div className="mb-6 text4">
          <p className="mb-3">{newPriceLabel}</p>
          <p className={`mb-0 ${PartnerPricingStyling.currentPrice}`}>
            {pricing.renderPrice(
              pricePlan,
              pricing.PRICING_PROPERTIES.MONTHLY
            )}
          </p>
          <p>/month per zone</p>
        </div>
        <LinkButton
          type={btn.linkType}
          text={btn.text}
          isExternal={btn.pressForExternalLinking}
          internalLink={btn.slug}
          externalLink={btn.externalLink}
          buttonSize={btn.buttonSize}
        />
      </div>
      <div className="col m-4 mono-container">
        <StructuredTextRenderer
          data={structuredText}
          isParagraphClassName="mb-5"
          isListItemClassName="mb-5"
        />
      </div>
    </Section>
  );
};

export default PartnerPricing;

export const query = graphql`
  fragment PartnerPricingReference on DatoCmsPartnerpricingreference {
    model {
      apiKey
    }
    id: originalId
    partnerPricing {
      id: originalId
      title
      voucher
      originalPriceLabel
      newPriceLabel
      partnerPricingButton {
        __typename
        ...Link
      }
      introText {
        value
      }
    }
  }
`
